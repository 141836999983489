<h2>Bank Details Verifications</h2>
<hr />
<div>
  <mat-paginator [length]="bankVerificationRequests.totalRecords"
                 [pageIndex]="paginationRequest.pageIndex"
                 [pageSize]="paginationRequest.pageSize"
                 [pageSizeOptions]="[10, 20, 30, 40, 50]"
                 showFirstLastButtons
                 aria-label="Select page of Bank Verifications"
                 (page)="onPageChange($event)">
  </mat-paginator>

  <div style="display: block; overflow-x: auto; white-space: nowrap; min-height: 10rem; min-height: 230px;">
    <table mat-table [dataSource]="bankVerificationRequests.response" matSort (matSortChange)="onColumnSort($event)" class="mat-elevation-z0 w-100">
      <ng-container matColumnDef="PersonCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IB ID" style="min-width: 13rem">
          IB Person Code
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator person code search icon" fontIcon="search" (click)="onShowPersonCodeSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showPersonCodeSearch = false">
            <table-search [showSearch]="showPersonCodeSearch" (searchQueryChangeEvent)="onPersonCodeChange($event)" (showSearchEvent)="onShowPersonCodeSearch($event)"></table-search>
          </div>
        </th>

        <td mat-cell *matCellDef="let element">{{ element.personCode }}</td>
      </ng-container>

      <ng-container matColumnDef="FirstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator First Name" style="min-width: 11rem">
          First Name
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator first name search icon" fontIcon="search" (click)="onShowFirstNameSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showFirstNameSearch = false">
            <table-search [showSearch]="showFirstNameSearch" (searchQueryChangeEvent)="onFirstNameChange($event)" (showSearchEvent)="onShowFirstNameSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
      </ng-container>

      <ng-container matColumnDef="LastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Educator Last Name" style="min-width: 11rem">
          Last Name
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Educator last name search icon" fontIcon="search" (click)="onShowLastNameSearch($event)"></mat-icon>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showLastNameSearch = false">
            <table-search [showSearch]="showLastNameSearch" (searchQueryChangeEvent)="onLastNameChange($event)" (showSearchEvent)="onShowLastNameSearch($event)"></table-search>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="DateCreated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date Created" style="min-width: 9rem">
          Date created
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Date Created filter icon" fontIcon="calendar_today" (click)="onShowDateCreatedFilter($event)"></mat-icon>
          <div style="position: absolute; width: 20rem;" (outsideClick)="showDateCreatedFilter = false">
            <table-date-filter [showFilter]="showDateCreatedFilter" (closeFilterEvent)="showDateCreatedFilter" (filterChangedEvent)="onDateCreatedFilterChange($event)"></table-date-filter>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dateCreated | date }}</td>
      </ng-container>

      <ng-container matColumnDef="DateClosed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Date Closed" style="min-width: 9rem">
          Date closed
          <mat-icon class="ms-1" aria-hidden="false" aria-label="Date Closed filter icon" fontIcon="calendar_today" (click)="onShowDateClosedFilter($event)"></mat-icon>
          <div style="position: absolute; width: 20rem;" (outsideClick)="showDateClosedFilter = false">
            <table-date-filter [showFilter]="showDateClosedFilter" (closeFilterEvent)="showDateClosedFilter" (filterChangedEvent)="onDateClosedFilterChange($event)"></table-date-filter>
          </div>
        </th>
        <td mat-cell *matCellDef="let element">{{ element.dateClosed != null ? (element.dateClosed | date) : 'N/A' }}</td>
      </ng-container>

      <ng-container matColumnDef="IBAN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by IBAN" style="min-width: 16rem">IBAN</th>

        <td mat-cell *matCellDef="let element"> {{ element.iban }}</td>
      </ng-container>

      <ng-container matColumnDef="Type">
        <th mat-header-cell *matHeaderCellDef style="min-width: 7rem">
          <div class="ms-1 d-flex align-items-center">
            Type
            <mat-icon aria-hidden="false" aria-label="Type filter icon" fontIcon="arrow_drop_down" (click)="onShowTypeFilter($event)"></mat-icon>
          </div>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showTypeFilter = false">
            <table-filter [enumDescriptions]="['Experian', 'Manual']" [showFilter]="showTypeFilter" (filterChangedEvent)="onTypeFilterChange($event)"></table-filter>
          </div>
        </th>

        <td mat-cell *matCellDef="let element">{{ element.isManual ? 'Manual' : 'Experian' }}</td>
      </ng-container>

      <ng-container matColumnDef="StatusDescription">
        <th mat-header-cell *matHeaderCellDef>
          <div class="ms-1 d-flex align-items-center">
            Status
            <mat-icon aria-hidden="false" aria-label="Status filter icon" fontIcon="arrow_drop_down" (click)="onShowStatusFilter($event)"></mat-icon>
          </div>
          <div style="position: absolute; width: 15rem;" (outsideClick)="showStatusFilter = false">
            <table-filter [enumDescriptions]="statusEnumDescriptions" [showFilter]="showStatusFilter" (filterChangedEvent)="onStatusFilterChange($event)" selectedValue="1"></table-filter>
          </div>
        </th>

        <td mat-cell *matCellDef="let element">{{ element.statusDescription }}</td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button class="IBO__btn" [routerLink]="['/bank-verification', element.bankDetailsId]" [hidden]="element.status==0">Review</button>
          <button class="IBO__btn" (click)="onShowClosedDetails(element)" [hidden]="element.status==1">Details</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="bankVerificationRequests.totalRecords"
                 [pageIndex]="paginationRequest.pageIndex"
                 [pageSize]="paginationRequest.pageSize"
                 [pageSizeOptions]="[10, 20, 30, 40, 50]"
                 showFirstLastButtons
                 aria-label="Select page of Bank Verifications"
                 (page)="onPageChange($event)">
  </mat-paginator>
</div>
