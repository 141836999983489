<div class="container" *ngIf="showFilter">
  <mat-form-field appearance="fill">
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" (dateChange)="onDateRangeChange(false)">
      <input matEndDate formControlName="end" placeholder="End date" (dateChange)="onDateRangeChange(true)">
    </mat-date-range-input>
    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </mat-form-field>
  <div class="mt-3">
    <button class="btn btn-primary btn-sm" (click)="onResetDateRange()">Reset</button>
  </div>
</div>
