import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'table-search',
  templateUrl: './table-search.component.html',
  styleUrls: ['./table-search.component.css']
})

export class TableSearchComponent {
  @Input() showSearch: boolean;
  @Input() placeholder: string;
  @Output() searchQueryChangeEvent = new EventEmitter<string>();
  @Output() showSearchEvent = new EventEmitter<any>();
  protected query: string;

  constructor() {
    this.query = "";
    this.placeholder = "";
    this.showSearch = false;
  }

  onSearchQueryChange(event: any) {
    this.searchQueryChangeEvent.emit(event.target.value);
  }

  onShowSearch(event: any) {
    this.showSearchEvent.emit(event);
  }
}
