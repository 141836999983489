import { Injectable } from '@angular/core';
const ACCESS_TOKEN = 'access_token';

@Injectable()
export default class TokenService {
  getToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  saveToken(token: string): void {
    localStorage.setItem(ACCESS_TOKEN, token);
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }
}
