<h1 mat-dialog-title>Reject of #{{data.personCode}}</h1>
<hr />
<div mat-dialog-content>
  <div><label>Rejection reason*</label></div>
  <div>
    <select class="form-control" id="reason" (change)="onReasonChange($event)" required>
      <option value="">Select one...</option>
      <option>Invalid IBAN</option>
      <option>IBAN mismatch</option>
      <option>Invalid data</option>
      <option>Data mismatch</option>
      <option>Incomplete details</option>
      <option>Other</option>
    </select>
    <span [style.display]="reasonRequired ? 'block' : 'none'" style="font-size:smaller;" class="text-danger">Reason is required.</span>
  </div>
  <br/>
  <div><label>Comments*</label></div>
  <div>
    <textarea rows="5" class="form-control" id="comments" (change)="onCommentsChange($event)" placeholder="Type here..." required></textarea>
    <span [style.display]="commentsRequired ? 'block' : 'none'" style="font-size:smaller;" class="text-danger">Comments are required and not less than 6 characters long.</span>
  </div>
  </div>
  <hr />
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="IBO__btn--secondary" (click)="onCancelClick()">Cancel</button>
    <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onOkClick()">Send rejection</button>
  </div>
