import {
  Directive,
  EventEmitter,
  HostListener,
  Output,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[outsideClick]',
})
export default class OutsideClickDirective {
  @Output()
  outsideClick: EventEmitter<MouseEvent> = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  onClick(event: any): void {
    if (!this.elementRef.nativeElement.contains(event.target) &&
      !event.target.classList.contains('mat-calendar-body-cell-content') &&
      !event.target.classList.contains('mat-focus-indicator')) {
      this.outsideClick.emit(event);
    }
  }

  constructor(private elementRef: ElementRef) { }
}
