import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'table-date-filter',
  templateUrl: './table-date-filter.component.html',
  styleUrls: ['./table-date-filter.component.css']
})

export class TableDateFilterComponent {
  @Input() showFilter: boolean;
  @Output() filterChangedEvent = new EventEmitter<any>();
  @Output() closeFilterEvent = new EventEmitter<any>();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor() {
    this.showFilter = false;
  }

  onDateRangeChange(isEndDate: boolean) {
    this.filterChangedEvent.emit({
      start: this.range.controls["start"].value,
      end: this.range.controls["end"].value
    });

    if (isEndDate && this.range.controls["end"].value != null) {
      this.showFilter = false;
      this.closeFilterEvent.emit();
    }
  }

  onResetDateRange() {
    this.range = new FormGroup({
      start: new FormControl<Date | null>(null),
      end: new FormControl<Date | null>(null),
    });

    this.onDateRangeChange(true);
  }
}
