import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BankVerificationRequest } from '../../../models/BankVerificationRequest';
import { CaseStatus, CaseStatusDescriptions } from '../../../models/enums/CaseStatus';
import { ValidationStatus, ValidationStatusDescriptions } from '../../../models/enums/ValidationStatus';
import { VerificationFeedback } from '../../../models/TaxVerificationFeedback';
import WebService from '../../../services/web.service';

export interface RejectionData {
  personCode: string;
}

@Component({
  selector: 'app-bank-verification',
  templateUrl: './bank-verification.component.html',
  styleUrls: ['./bank-verification.component.css']
})
export class BankVerificationComponent implements OnInit, OnDestroy {
  protected verificationRequest!: BankVerificationRequest;
  private id: string;
  private sub: any;
  protected showRejection: boolean;
  protected showValidationButtons: boolean;
  protected showGovDocument: boolean;
  protected showBankDocument: boolean;

  constructor(private webService: WebService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.id = "";
    this.showRejection = false;
    this.showValidationButtons = true;
    this.showGovDocument = false;
    this.showBankDocument = false;
  }

  onNavigateToBankVerifications() {
    this.router.navigate(['bank-verifications']);
  }


  async onGovDocumentDownload() {
    this.dialog.open(BankVerificationDocumentDialog, {
      width: '850px',
      height: '650px',
      data: { id: this.id, endpoint: 'gov_document', document: 'Goverment ID' }
    });
  }  

  async onBankDocumentDownload() {
    this.dialog.open(BankVerificationDocumentDialog, {
      width: '850px',
      height: '650px',
      data: { id: this.id, endpoint: 'bank_document', document: "Bank Statement" }
    });
  }

  async getData(id: string) {
    this.verificationRequest = await this.webService.get<BankVerificationRequest>('/bank/' + id);
    this.verificationRequest.statusDescription = CaseStatusDescriptions[this.verificationRequest.status];
    this.verificationRequest.validationStatusDescription = ValidationStatusDescriptions[this.verificationRequest.validationStatus];
    this.showValidationButtons = this.verificationRequest.status == CaseStatus.Open;
    this.showRejection = this.verificationRequest.validationStatus == ValidationStatus.DetailsRequired;
    this.showGovDocument = this.verificationRequest.nameMismatchGovDocId.length > 0;
    this.showBankDocument = this.verificationRequest.nameMismatchRecentBankDocuId.length > 0;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.getData(this.id);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  openApproveDialog(): void {
    const dialogRef = this.dialog.open(BankVerificationApproveDialog, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The approve dialog was closed with: ', result);
      if (result) {
        this.webService.post('/bank/' + this.id + '/approve', null).then(() => {
          this.getData(this.id);
        });
      }      
    });
  }

  openRejectDialog(): void {
    const dialogRef = this.dialog.open(BankVerificationRejectDialog, {
      width: '450px',
      data: { personCode: this.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The reject dialog was closed with: ', result);
      if (result) {
        var feedback: VerificationFeedback =
        {
          rejectionComment: result.comments,
          rejectionReason: result.reason
        };

        this.webService.post('/bank/' + this.id + '/reject', feedback).then(() => {
          this.getData(this.id);
        });
      }      
    });
  }  
}

@Component({
  selector: 'bank-verification-approve-dialog',
  templateUrl: 'bank-verification-approve-dialog.html',
})
export class BankVerificationApproveDialog {
  constructor(public dialogRef: MatDialogRef<BankVerificationApproveDialog>) { }

  onCancelClick(): void {
    this.dialogRef.close("");
  }

  onOkClick(): void {
    this.dialogRef.close("APPROVE");
  }
}


@Component({
  selector: 'bank-verification-reject-dialog',
  templateUrl: 'bank-verification-reject-dialog.html',
})
export class BankVerificationRejectDialog {
  protected reasonRequired!: boolean;
  protected commentsRequired!: boolean;
  protected reason!: string;
  protected comments!: string;
  constructor(
    public dialogRef: MatDialogRef<BankVerificationRejectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RejectionData,
  ) {
  }

  onCancelClick(): void {
    this.dialogRef.close("");
  }

  onOkClick(): void {
    this.reasonRequired = !this.reason;
    this.commentsRequired = !this.comments || this.comments.length < 6;

    if (this.reasonRequired || this.commentsRequired)
      return;

    this.dialogRef.close({ reason: this.reason, comments: this.comments });
  }

  onCommentsChange(e: any): void {
    this.comments = e.target.value;
    this.commentsRequired = !this.comments || this.comments.length < 6;
  }

  onReasonChange(e: any): void {
    this.reason = e.target.value;
    this.reasonRequired = !this.reason;
  }
}


@Component({
  selector: 'bank-verification-document-dialog',
  templateUrl: 'bank-verification-document-dialog.html',
})
export class BankVerificationDocumentDialog {
  src: SafeResourceUrl;
  isLoading: boolean;
  constructor(private webService: WebService, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<BankVerificationDocumentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isLoading = true;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(webService.BaseURI + '/bank/' + data.id + '/' + data.endpoint);
  }

  onCloseClick(): void {
    this.dialogRef.close("");
  }
}
