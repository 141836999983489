<h2>Review Bank Details</h2>
<hr />
<div class="row">
  <div class="col-md-8"><button class="IBO__btn--secondary" (click)="onNavigateToBankVerifications()">&lt; Back to all approvals</button></div>
  <div class="col-md-4">
    <button class="IBO__btn--secondary" (click)="openRejectDialog()" *ngIf="showValidationButtons">Reject</button>
    <button class="IBO__btn" (click)="openApproveDialog()" *ngIf="showValidationButtons">Validate</button>
  </div>
</div>
<div class="row">
  <div class="col-md-2"><label>IB Person Code</label></div>
  <div class="col-md-8">{{verificationRequest.personCode}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Educator name</label></div>
  <div class="col-md-8">{{verificationRequest.firstName}} {{verificationRequest.lastName}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Review status</label></div>
  <div class="col-md-8">{{verificationRequest.statusDescription}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Review created</label></div>
  <div class="col-md-8">{{verificationRequest.dateCreated | date}}</div>
</div>
<hr />
<div class="row">
  <div class="col-md-2"><label>Account name</label></div>
  <div class="col-md-8">{{verificationRequest.accountName}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Bank name</label></div>
  <div class="col-md-8">{{verificationRequest.bankName}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Bank address</label></div>
  <div class="col-md-8">{{verificationRequest.bankAddress1}} {{verificationRequest.bankAddress2}} {{verificationRequest.bankAddress3}} {{verificationRequest.bankCity}} {{verificationRequest.bankPoastlCode}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Branch number</label></div>
  <div class="col-md-8">{{verificationRequest.branchNumber}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Account number</label></div>
  <div class="col-md-8">{{verificationRequest.accountNumber}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>IBAN</label></div>
  <div class="col-md-8">{{verificationRequest.iban}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Account BIC/CODE</label></div>
  <div class="col-md-8">{{verificationRequest.bic}}</div>
</div>
<div class="row" *ngIf="!showValidationButtons">
  <div class="col-md-2"><label>Validation status</label></div>
  <div class="col-md-8">{{verificationRequest.validationStatusDescription}}</div>
</div>
<div class="row" *ngIf="showRejection">
  <div class="col-md-2"><label>Rejection reason</label></div>
  <div class="col-md-8">{{verificationRequest.rejectionReason}}</div>
</div>
<div class="row" *ngIf="showRejection">
  <div class="col-md-2"><label>Comments</label></div>
  <div class="col-md-8">{{verificationRequest.rejectionComments}}</div>
</div>
<div class="row">
  <div class="col-md-2"><label>Mismatch explanation</label></div>
  <div class="col-md-8">{{verificationRequest.accountNameMismatchExplanation}}</div>
</div>
<hr />
<div class="row" *ngIf="showGovDocument">
  <div class="col-md-2"><label>Government-Issued ID</label></div>
  <div class="col-md-8"><button class="IBO__btn--primary IBO_df-btn" (click)="onGovDocumentDownload()">View document</button></div>
</div>
<div class="row" *ngIf="showBankDocument">
  <div class="col-md-2"><label>Recent Bank Statement</label></div>
  <div class="col-md-8"><button class="IBO__btn--primary IBO_df-btn" (click)="onBankDocumentDownload()">View document</button></div>
</div>
