export enum ValidationStatus {
  RequiresValidation = 1,
  Validated = 2,
  DetailsRequired = 3
}

export const ValidationStatusDescriptions: Record<ValidationStatus, string> = {
  [ValidationStatus.RequiresValidation]: 'Requires Validation',
  [ValidationStatus.Validated]: 'Validated',
  [ValidationStatus.DetailsRequired]: 'Details Required',
};
