import { Injectable } from '@angular/core';
import { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { environment } from '../environments/environment';
import axios from "axios";
import TokenService from './token.service';

@Injectable()
export default class WebService {
  public readonly BaseURI = environment.apiBaseUri;  
  private axiosClient: AxiosInstance; 

  constructor(private tokenService: TokenService) {
    this.axiosClient = axios.create({
      timeout: 10000
    });

    this.axiosClient.interceptors.request.use(config => {
      let token = tokenService.getToken();

      if (token && config && config.headers) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    }, function (error: AxiosError) {
      return Promise.reject(error);
    });

    var self = this;
    this.axiosClient.interceptors.response.use(function (response: AxiosResponse) {
      return response;
    }, function (error: AxiosError) {
      if (error && error.response && error.response.status == 401) {
        self.beginAuthFlow();
      }
      return Promise.reject(error);
    });
  }

  public async get<T>(uri: string): Promise<T> {
    try {
      var axiosResponse = await this.axiosClient.request<T>({
        method: 'get',
        url: this.BaseURI + uri
      });
      return (axiosResponse.data);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async getFile(uri: string) {
    try {
      const axiosResponse = await this.axiosClient.get(this.BaseURI + uri, {
        responseType: 'blob'
      })
      return (axiosResponse);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  public async post<T>(uri: string, body: any): Promise<T> {
    try {
      var axiosResponse = await this.axiosClient.request<T>({
        method: 'post',
        url: this.BaseURI + uri,
        data: body,
      });
      return (axiosResponse.data);
    } catch (error: any) {
      return Promise.reject(error);
    }
  }

  private async beginAuthFlow(): Promise<void> {
    this.tokenService.removeToken();
    let codeUrl = await this.get<string>('/auth/GetSSOUrl');
    window.location.href = codeUrl;
  }
}
