import { Component, Inject, OnInit } from '@angular/core';
import { TaxReferenceTypeDescriptions } from '../../../models/enums/TaxReferenceType';
import { CaseStatus, CaseStatusDescriptions } from '../../../models/enums/CaseStatus';
import { PaginationResponse } from '../../../models/pagination/PaginationResponse';
import { TaxPaginationRequest } from '../../../models/pagination/TaxPaginationRequest';
import { TaxVerificationRequest } from '../../../models/TaxVerificationRequest';
import WebService from '../../../services/web.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationStatus, ValidationStatusDescriptions } from '../../../models/enums/ValidationStatus';
import { formatDate } from '@angular/common';
import { LOCALE_ID } from "@angular/core";

@Component({
  selector: 'tax-verifications',
  templateUrl: './tax-verifications.component.html',
})

export class TaxVerificationsComponent implements OnInit {
  protected tableColumns: string[] = ['PersonCode', 'FirstName', 'LastName', 'DateCreated', 'DateClosed', 'StatusDescription', 'TaxReferenceTypeDescription', 'Actions'];
  protected verificationRequests: TaxVerificationRequest[] = [];
  protected taxVerificationRequests: PaginationResponse<TaxVerificationRequest>;
  protected paginationRequest: TaxPaginationRequest;
  protected showIDSearch: boolean;
  protected showFirstNameSearch: boolean;
  protected showLastNameSearch: boolean;
  protected showStatusFilter: boolean;
  protected showSubmissionTypeFilter: boolean;
  protected showDateCreatedFilter: boolean;
  protected showDateClosedFilter: boolean;
  protected statusEnumDescriptions: string[] = Object.values(CaseStatusDescriptions);
  protected typeEnumDescriptions: string[] = Object.values(TaxReferenceTypeDescriptions);

  constructor(private webService: WebService, public dialog: MatDialog) {
    this.paginationRequest = {
      pageIndex: 0,
      pageSize: 10,
      sortOrder: null,
      searchQuery: null,
      status: CaseStatus.Open,
      taxReferenceType: null,
      firstName: null,
      lastName: null,
      id: null,
      dateCreatedStart: null,
      dateCreatedEnd: null,
      dateClosedStart: null,
      dateClosedEnd: null
    } as TaxPaginationRequest;

    this.taxVerificationRequests = {
      response: [],
      totalRecords: 0
    } as PaginationResponse<TaxVerificationRequest>;

    this.showIDSearch = false;
    this.showFirstNameSearch = false;
    this.showLastNameSearch = false;
    this.showStatusFilter = false;
    this.showSubmissionTypeFilter = false;
    this.showDateCreatedFilter = false;
    this.showDateClosedFilter = false;
  }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.taxVerificationRequests = await this.webService.post<PaginationResponse<TaxVerificationRequest>>('/tax/', this.paginationRequest);
    this.taxVerificationRequests.response.forEach(x => {
      x.statusDescription = CaseStatusDescriptions[x.status];
      x.taxReferenceTypeDescription = TaxReferenceTypeDescriptions[x.taxReferenceType]
    });
  }

  onPageChange(event: any) {
    this.paginationRequest.pageIndex = event.pageIndex;
    this.paginationRequest.pageSize = event.pageSize;
    this.getData();
  }

  onColumnSort(event: any) {
    this.paginationRequest.pageIndex = 0;

    if (event.direction) {
      this.paginationRequest.sortOrder = `${event.active}_${event.direction}`;
    }
    else {
      this.paginationRequest.sortOrder = null;
    }

    this.getData();
  }

  onIDChange(query: string) {
    if (!query) {
      this.paginationRequest.id = null;
    }
    else {
      var parsed = +query;

      if (isNaN(parsed)) {
        return;
      }

      this.paginationRequest.id = parsed;
    }

    this.getData();
  }

  onFirstNameChange(query: string) {
    this.paginationRequest.firstName = query;

    if (this.paginationRequest.firstName.length >= 3 || this.paginationRequest.firstName.length == 0) {
      this.getData();
    }
  }

  onShowIDSearch(event: any) {
    event.stopPropagation();
    this.showIDSearch = !this.showIDSearch;
  }

  onShowFirstNameSearch(event: any) {
    event.stopPropagation();
    this.showFirstNameSearch = !this.showFirstNameSearch;
  }

  onLastNameChange(query: string) {
    this.paginationRequest.lastName = query;

    if (this.paginationRequest.lastName.length >= 3 || this.paginationRequest.lastName.length == 0) {
      this.getData();
    }
  }

  onShowDateCreatedFilter(event: any) {
    event.stopPropagation();
    this.showDateCreatedFilter = !this.showDateCreatedFilter;
  }

  onShowDateClosedFilter(event: any) {
    event.stopPropagation();
    this.showDateClosedFilter = !this.showDateClosedFilter;
  }

  onShowLastNameSearch(event: any) {
    event.stopPropagation();
    this.showLastNameSearch = !this.showLastNameSearch;
  }

  onShowStatusFilter(event: any) {
    event.stopPropagation();
    this.showStatusFilter = !this.showStatusFilter;
  }

  onStatusFilterChange(event: number) {
    this.paginationRequest.status = event;
    this.getData();
  }

  onShowSubmissionTypeFilter(event: any) {
    event.stopPropagation();
    this.showSubmissionTypeFilter = !this.showSubmissionTypeFilter;
  }

  onSubmissionTypeFilterChange(event: number) {
    this.paginationRequest.taxReferenceType = event;
    this.getData();
  }

  onDateCreatedFilterChange(event: any) {
    this.paginationRequest.dateCreatedStart = event.start;
    this.paginationRequest.dateCreatedEnd = event.end;
    this.getData();
  }

  onDateClosedFilterChange(event: any) {
    this.paginationRequest.dateClosedStart = event.start;
    this.paginationRequest.dateClosedEnd = event.end;
    this.getData();
  }

  onShowClosedDetails(element: TaxVerificationRequest) {
    this.dialog.open(TaxVerificationDetailsDialog, {
      width: '850px',
      height: '650px',
      data: element
    });
  }
}

@Component({
  selector: 'tax-verification-details-dialog',
  templateUrl: 'tax-verification-details-dialog.html',
})
export class TaxVerificationDetailsDialog {
  src: SafeResourceUrl;
  isLoading: boolean = true;
  showRejection: boolean;  
  constructor(private webService: WebService, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<TaxVerificationDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TaxVerificationRequest,
    @Inject(LOCALE_ID) localID: string
  ) {    
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(webService.BaseURI + '/tax/documents/' + data.taxDocumentId);
    this.showRejection = this.data.validationStatus == ValidationStatus.DetailsRequired;
    this.data.validationStatusDescription = ValidationStatusDescriptions[this.data.validationStatus];
    this.data.dateCreatedFormatted = formatDate(this.data.dateCreated, "M/d/yy, h:mm a", localID);
    if (this.data.dateClosed) {
      this.data.dateClosedFormatted = formatDate(this.data.dateClosed as Date, "M/d/yy, h:mm a", localID);
    }    
  }

  onCloseClick(): void {
    this.dialogRef.close("");
  }
}
