<h1 mat-dialog-title>Confirm bank status override</h1>
<hr />
  <div mat-dialog-content>
  <div><label>New Status*</label></div>
  <div>
      You are going to set status 'Details Required', confirm?
  </div>  
  </div>
  <hr />
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="IBO__btn--secondary" (click)="onCancelClick()">Cancel</button>
    <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onOkClick()">Confirm</button>
  </div>
