<h1 mat-dialog-title>Bank Review Details</h1>
<hr />
<div mat-dialog-content style="height:560px">
  <div class="row">
    <div class="col-md-2"><label>IB Person Code</label></div>
    <div class="col-md-4">{{data.personCode}}</div>
    <div class="col-md-2"><label>Educator name</label></div>
    <div class="col-md-4">{{data.firstName}} {{data.lastName}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Review status</label></div>
    <div class="col-md-4">{{data.statusDescription}}</div>
    <div class="col-md-2"><label>Mismatch explanation</label></div>
    <div class="col-md-4">{{data.accountNameMismatchExplanation}}</div>    
  </div>
  <hr />
  <div class="row">
    <div class="col-md-2"><label>Account name</label></div>
    <div class="col-md-4">{{data.accountName}}</div>
    <div class="col-md-2"><label>Bank name</label></div>
    <div class="col-md-4">{{data.bankName}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Bank address</label></div>
    <div class="col-md-4">{{data.bankAddress1}} {{data.bankAddress2}} {{data.bankAddress3}} {{data.bankCity}} {{data.bankPoastlCode}}</div>
    <div class="col-md-2"><label>Branch number</label></div>
    <div class="col-md-4">{{data.branchNumber}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Account number</label></div>
    <div class="col-md-4">{{data.accountNumber}}</div>
    <div class="col-md-2"><label>IBAN, BIC/CODE</label></div>
    <div class="col-md-4">{{data.iban}}, {{data.bic}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Validation status</label></div>
    <div class="col-md-4">{{data.validationStatusDescription}}</div>
    <div class="col-md-2"><label>Review created</label></div>
    <div class="col-md-4">{{data.dateCreatedFormatted}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Rejection reason</label></div>
    <div class="col-md-4">{{data.rejectionReason}}</div>
    <div class="col-md-2"><label>Review closed</label></div>
    <div class="col-md-4">{{data.dateClosedFormatted}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Comments</label></div>
    <div class="col-md-4">{{data.rejectionComments}}</div>
    <div class="col-md-2"><label>Reviewed by</label></div>
    <div class="col-md-4">{{data.updatedBy}}</div>
  </div>
  <hr />
  <div class="row">
    <div class="col-md-6"><label>Government-Issued ID</label></div>
    <div class="col-md-6"><label>Recent Bank Statement</label></div>
  </div>
  <div class="row" style="height:320px;">
    <div class="col-md-6">
      <mat-spinner [hidden]="!isLoadingGov" style="margin:0 auto;"></mat-spinner>
      <iframe width="100%" height="100%" [src]="srcGov" (load)="isLoadingGov=false" [class.d-none]="isLoadingGov"></iframe>
    </div>
    <div class="col-md-6">
      <mat-spinner [hidden]="!isLoadingBank" style="margin:0 auto;"></mat-spinner>
      <iframe width="100%" height="100%" [src]="srcBank" (load)="isLoadingBank=false" [class.d-none]="isLoadingBank"></iframe>
    </div>
  </div>
</div>
<hr/>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onCloseClick()">Close</button>
</div>
