import { Component, Inject, OnInit } from '@angular/core';
import { BankVerificationRequest } from '../../../models/BankVerificationRequest';
import { CaseStatus, CaseStatusDescriptions } from '../../../models/enums/CaseStatus';
import { BankPaginationRequest } from '../../../models/pagination/BankPaginationRequest';
import { PaginationResponse } from '../../../models/pagination/PaginationResponse';
import WebService from '../../../services/web.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationStatus, ValidationStatusDescriptions } from '../../../models/enums/ValidationStatus';
import { formatDate } from '@angular/common';
import { LOCALE_ID } from "@angular/core";

@Component({
  selector: 'bank-verifications',
  templateUrl: './bank-verifications.component.html',
})

export class BankVerificationsComponent implements OnInit {
  protected tableColumns: string[] = ['PersonCode', 'FirstName', 'LastName', 'DateCreated', 'DateClosed', 'StatusDescription', 'IBAN', 'Type', 'Actions'];
  protected verificationRequests: BankVerificationRequest[] = [];
  protected bankVerificationRequests: PaginationResponse<BankVerificationRequest>;
  protected paginationRequest: BankPaginationRequest;
  protected showPersonCodeSearch: boolean;
  protected showFirstNameSearch: boolean;
  protected showLastNameSearch: boolean;
  protected showStatusFilter: boolean;
  protected showDateCreatedFilter: boolean;
  protected showDateClosedFilter: boolean;
  protected showTypeFilter: boolean;
  protected showSubmissionTypeFilter: boolean;
  protected statusEnumDescriptions: string[] = Object.values(CaseStatusDescriptions);
  
  constructor(private webService: WebService, public dialog: MatDialog) {
    this.paginationRequest = {
      pageIndex: 0,
      pageSize: 10,
      sortOrder: null,
      searchQuery: null,
      status: CaseStatus.Open,
      iBAN: null,
      firstName: null,
      lastName: null,
      id: null,
      dateCreatedStart: null,
      dateCreatedEnd: null,
      dateClosedStart: null,
      dateClosedEnd: null,
      isManual: null
    } as BankPaginationRequest;

    this.bankVerificationRequests = {
      response: [],
      totalRecords: 0
    } as PaginationResponse<BankVerificationRequest>;

    this.showPersonCodeSearch = false;
    this.showFirstNameSearch = false;
    this.showLastNameSearch = false;
    this.showStatusFilter = false;
    this.showTypeFilter = false;
    this.showSubmissionTypeFilter = false;
    this.showDateCreatedFilter = false;
    this.showDateClosedFilter = false;
  }

  ngOnInit() {
    this.getData();
  }

  async getData() {
    this.bankVerificationRequests = await this.webService.post<PaginationResponse<BankVerificationRequest>>('/bank/', this.paginationRequest);
    this.bankVerificationRequests.response.forEach(x => {
      x.statusDescription = CaseStatusDescriptions[x.status];      
    });
  }

  onPageChange(event: any) {
    this.paginationRequest.pageIndex = event.pageIndex;
    this.paginationRequest.pageSize = event.pageSize;
    this.getData();
  }

  onColumnSort(event: any) {
    this.paginationRequest.pageIndex = 0;

    if (event.direction) {
      this.paginationRequest.sortOrder = `${event.active}_${event.direction}`;
    }
    else {
      this.paginationRequest.sortOrder = null;
    }

    this.getData();
  }

  onPersonCodeChange(query: string) {
    if (!query) {
      this.paginationRequest.id = null;

    } else {
      var parsed = +query;

      if (isNaN(parsed)) {
        return;
      }

      this.paginationRequest.id = parsed;
    }
   
    this.getData();
  }

  onShowPersonCodeSearch(event: any) {
    event.stopPropagation();
    this.showPersonCodeSearch = !this.showPersonCodeSearch;
  }

  onFirstNameChange(query: string) {
    this.paginationRequest.firstName = query;

    if (this.paginationRequest.firstName.length >= 3 || this.paginationRequest.firstName.length == 0) {
      this.getData();
    }
  }

  onShowFirstNameSearch(event: any) {
    event.stopPropagation();
    this.showFirstNameSearch = !this.showFirstNameSearch;
  }

  onLastNameChange(query: string) {
    this.paginationRequest.lastName = query;

    if (this.paginationRequest.lastName.length >= 3 || this.paginationRequest.lastName.length == 0) {
      this.getData();
    }
  }
  
  onShowLastNameSearch(event: any) {
    event.stopPropagation();
    this.showLastNameSearch = !this.showLastNameSearch;
  }

  onShowDateCreatedFilter(event: any) {
    event.stopPropagation();
    this.showDateCreatedFilter = !this.showDateCreatedFilter;
  }

  onShowDateClosedFilter(event: any) {
    event.stopPropagation();
    this.showDateClosedFilter = !this.showDateClosedFilter;
  }

  onDateCreatedFilterChange(event: any) {
    this.paginationRequest.dateCreatedStart = event.start;
    this.paginationRequest.dateCreatedEnd = event.end;
    this.getData();
  }

  onDateClosedFilterChange(event: any) {
    this.paginationRequest.dateClosedStart = event.start;
    this.paginationRequest.dateClosedEnd = event.end;
    this.getData();
  }

  onShowStatusFilter(event: any) {
    event.stopPropagation();
    this.showStatusFilter = !this.showStatusFilter;
  }

  onStatusFilterChange(event: number) {
    this.paginationRequest.status = event;
    this.getData();
  }

  onShowSubmissionTypeFilter(event: any) {
    event.stopPropagation();
    this.showSubmissionTypeFilter = !this.showSubmissionTypeFilter;
  }

  onSubmissionTypeFilterChange(event: number) {
    this.getData();
  }

  onShowTypeFilter(event: any) {
    event.stopPropagation();
    this.showTypeFilter = !this.showTypeFilter;
  }

  onTypeFilterChange(event: number) {
    if (isNaN(event)) {
      this.paginationRequest.isManual = null;
    }
    else {
      this.paginationRequest.isManual = Boolean(event).valueOf();
    }

    this.getData();
  }
  
  onShowClosedDetails(element: BankVerificationRequest) {
    this.dialog.open(BankVerificationDetailsDialog, {
      width: '1000px',
      height: '750px',
      data: element
    });
  }
}

@Component({
  selector: 'bank-verification-details-dialog',
  templateUrl: 'bank-verification-details-dialog.html',
})
export class BankVerificationDetailsDialog {
  srcGov: SafeResourceUrl;
  srcBank: SafeResourceUrl;
  isLoadingGov: boolean = true;
  isLoadingBank: boolean = true;
  showRejection: boolean;
  constructor(private webService: WebService, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<BankVerificationDetailsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: BankVerificationRequest,
    @Inject(LOCALE_ID) localID: string
  ) {
    this.srcGov = this.sanitizer.bypassSecurityTrustResourceUrl(webService.BaseURI + '/bank/gov_documents/' + data.nameMismatchGovDocId);
    this.srcBank = this.sanitizer.bypassSecurityTrustResourceUrl(webService.BaseURI + '/bank/bank_document/' + data.nameMismatchRecentBankDocuId);
    this.showRejection = this.data.validationStatus == ValidationStatus.DetailsRequired;
    this.data.validationStatusDescription = ValidationStatusDescriptions[this.data.validationStatus];
    this.data.dateCreatedFormatted = formatDate(this.data.dateCreated, "M/d/yy, h:mm a", localID);
    if (this.data.dateClosed) {
      this.data.dateClosedFormatted = formatDate(this.data.dateClosed as Date, "M/d/yy, h:mm a", localID);
    }
  }

  onCloseClick(): void {
    this.dialogRef.close("");
  }
}

