<h1 mat-dialog-title>Tax Review Details</h1>
<hr />
<div mat-dialog-content style="height:450px">
  <div class="row">
    <div class="col-md-2"><label>IB Person Code</label></div>
    <div class="col-md-4">{{data.personCode}}</div>
    <div class="col-md-2"><label>Educator name</label></div>
    <div class="col-md-4">{{data.firstName}} {{data.lastName}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Status</label></div>
    <div class="col-md-4">{{data.statusDescription}}</div>
    <div class="col-md-2"><label>Validation status</label></div>
    <div class="col-md-4">{{data.validationStatusDescription}}</div>

  </div>

  <div class="row">
    <div class="col-md-2"><label>SSN</label></div>
    <div class="col-md-4">{{data.taxNumber}}</div>
    <div class="col-md-2"><label>Review created at</label></div>
    <div class="col-md-4">{{data.dateCreatedFormatted}}</div>
  </div>

  <div class="row">
    <div class="col-md-2"><label>Rejection reason</label></div>
    <div class="col-md-4">{{data.rejectionReason}}</div>
    <div class="col-md-2"><label>Review closed at</label></div>
    <div class="col-md-4">{{data.dateClosedFormatted}}</div>
  </div>
  <div class="row">
    <div class="col-md-2"><label>Comments</label></div>
    <div class="col-md-4">{{data.rejectionComments}}</div>
    <div class="col-md-2"><label>Reviewed by</label></div>
    <div class="col-md-4">{{data.updatedBy}}</div>
  </div>
  <hr />
  <div class="row" style="height:310px;">
    <div class="col-md-12">
      <mat-spinner [hidden]="!isLoading" style="margin:0 auto;"></mat-spinner>
      <iframe width="100%" height="100%" [src]="src" (load)="isLoading=false" [class.d-none]="isLoading"></iframe>
    </div>
  </div>
</div>
<hr/>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onCloseClick()">Close</button>
</div>
