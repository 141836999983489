import { Injectable } from '@angular/core';
import TokenService from './token.service';
import WebService from './web.service';

@Injectable()
export default class AuthService {
  constructor(private tokenService: TokenService, private webService: WebService) {}

  async beginAuthFlow() : Promise<void> {
    this.tokenService.removeToken();
    let codeUrl = await this.webService.get<string>('/auth/');
    window.location.href = codeUrl;
  }
}
