import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TaxVerificationRequest } from '../../../models/TaxVerificationRequest';
import { CaseStatusDescriptions } from '../../../models/enums/CaseStatus';
import { ValidationStatus, ValidationStatusDescriptions } from '../../../models/enums/ValidationStatus';
import WebService from '../../../services/web.service';
import { VerificationFeedback } from '../../../models/TaxVerificationFeedback';
import { CaseStatus } from '../../../models/enums/CaseStatus';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export interface RejectionData {
  personCode: string;
}

@Component({
  selector: 'tax-verification',
  templateUrl: './tax-verification.component.html',
})

export class TaxVerificationComponent implements OnInit, OnDestroy {
  protected verificationRequest!: TaxVerificationRequest;
  private id: string;
  private sub: any;
  protected showRejection: boolean;
  protected showValidationButtons: boolean;

  constructor(private webService: WebService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog) {
    this.id = "";
    this.showRejection = false;
    this.showValidationButtons = true;
  }

  onNavigateToTaxVerifications() {
    this.router.navigate(['tax-verifications']);
  }

  async onTaxDocumentDownload() {    
    this.dialog.open(TaxVerificationDocumentDialog, {
      width: '850px',
      height: '650px',
      data: this.id
    });
  }

  async getData(id: string) {
    this.verificationRequest = await this.webService.get<TaxVerificationRequest>('/tax/' + id);
    this.verificationRequest.statusDescription = CaseStatusDescriptions[this.verificationRequest.status];
    this.verificationRequest.validationStatusDescription = ValidationStatusDescriptions[this.verificationRequest.validationStatus];
    this.showValidationButtons = this.verificationRequest.status == CaseStatus.Open;
    this.showRejection = this.verificationRequest.validationStatus == ValidationStatus.DetailsRequired;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.id = params['id']; 
      this.getData(this.id);      
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  openApproveDialog(): void {
    const dialogRef = this.dialog.open(TaxVerificationApproveDialog, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The approve dialog was closed with: ', result);
      if (result) {
        this.webService.post('/tax/' + this.id + '/approve', null).then(() => {
          this.getData(this.id);
        });
      }      
    });
  }

  openRejectDialog(): void {
    const dialogRef = this.dialog.open(TaxVerificationRejectDialog, {
      width: '450px',
      data: { personCode: this.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The reject dialog was closed with: ', result);

      if (result) {
        var feedback: VerificationFeedback =
        {
          rejectionComment: result.comments,
          rejectionReason: result.reason
        };

        this.webService.post('/tax/' + this.id + '/reject', feedback).then(() => {
          this.getData(this.id);
        });
      }      
    });
  }  
}

@Component({
  selector: 'tax-verification-document-dialog',
  templateUrl: 'tax-verification-document-dialog.html',
})
export class TaxVerificationDocumentDialog {  
  src: SafeResourceUrl;
  isLoading: boolean;
  constructor(private webService: WebService, private sanitizer: DomSanitizer, public dialogRef: MatDialogRef<TaxVerificationDocumentDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.isLoading = true;
    this.src = this.sanitizer.bypassSecurityTrustResourceUrl(webService.BaseURI + '/tax/' + data + '/document');    
  }

  onCloseClick(): void {
    this.dialogRef.close("");
  }
}

@Component({
  selector: 'tax-verification-approve-dialog',
  templateUrl: 'tax-verification-approve-dialog.html',
})
export class TaxVerificationApproveDialog {
  constructor(public dialogRef: MatDialogRef<TaxVerificationApproveDialog>) { }

  onCancelClick(): void {
    this.dialogRef.close("");
  }

  onOkClick(): void {
    this.dialogRef.close("APPROVE");
  }
}


@Component({
  selector: 'tax-verification-reject-dialog',
  templateUrl: 'tax-verification-reject-dialog.html',
})
export class TaxVerificationRejectDialog {
  protected reasonRequired!: boolean;
  protected commentsRequired!: boolean;
  protected reason!: string;
  protected comments!: string;
  constructor(
    public dialogRef: MatDialogRef<TaxVerificationRejectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: RejectionData,
  ) {    
  }

  onCancelClick(): void {
    this.dialogRef.close("");
  }

  onOkClick(): void {
    this.reasonRequired = !this.reason;
    this.commentsRequired = !this.comments || this.comments.length < 6;

    if (this.reasonRequired || this.commentsRequired)
      return;

    this.dialogRef.close({reason: this.reason, comments: this.comments});
  }

  onReasonChange(e: any): void {
    this.reason = e.target.value;
    this.reasonRequired = !this.reason;
  }

  onCommentsChange(e: any): void {    
    this.comments = e.target.value;
    this.commentsRequired = !this.comments || this.comments.length < 6;
  }
}
