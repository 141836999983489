<h1 mat-dialog-title>View {{data.document}} Document</h1>
<hr />
<div mat-dialog-content style="height: 450px; vertical-align: central;">
  <iframe width="100%" height="100%" [src]="src" (load)="isLoading=false" [class.d-none]="isLoading"></iframe>  
  <mat-spinner *ngIf="isLoading" style="margin:0 auto;"></mat-spinner>
</div>
<hr/>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial class="IBO__btn--primary" (click)="onCloseClick()">Close</button>
</div>
